<script>
import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import store from "@/state/store";
import { required } from "vuelidate/lib/validators";
import Multiselect from "vue-multiselect";
export default {
  beforeRouteEnter(to, from, next) {
    const userType = store.getters["auth/getUser"].user_type;
    const permissions = store.getters["auth/permissions"];
    if (
      (userType === 1 || userType === 3) &&
      permissions.includes("PAYROLL_CONFIG")
    ) {
      next();
    } else {
      alert("You are not allowed to access this page. You will be redirected.");
      next("/");
    }
  },
  page: {
    title: "Exemption",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: {
    Layout,
    PageHeader,
    Multiselect,
  },
  mounted() {
    this.refreshTable();
    this.fetchPMY();
    this.fetchEmployees();
  },
  validations: {
    employee: { required },
  },
  methods: {
    refreshTable() {
      const url = `${this.ROUTES.salary}/pause-salary`;
      this.apiGet(url, "Get Exemptions Error").then(async (res) => {
        const { data } = res;
        let index = 0;
        for (const exemption of data) {
          const location = await this.getLocation(
            exemption.employee.emp_location_id
          );
          let exemptionObj = {
            sn: ++index,
            employee: `${exemption.employee.emp_first_name} ${
              exemption.employee.emp_other_name ?? ""
            } ${exemption.employee.emp_last_name} - ${
              exemption.employee.emp_unique_id
            }`,
            location,
            month: exemption.ps_month,
            year: exemption.ps_year,
            ps_id: exemption.ps_id,
          };
          this.exemptions.push(exemptionObj);
        }
      });
    },
    async getLocation(locationId) {
      const url = `${this.ROUTES.location}/${locationId}`;
      return await this.apiGet(url, "Couldn't get location details").then(
        (res) => {
          const { data } = res;
          return data.location_name;
        }
      );
    },
    fetchPMY() {
      this.apiGet(
        this.ROUTES.payrollMonthYear,
        "Get Payroll Month & Year Error"
      ).then((res) => {
        if (res.data) {
          const { pym_year, pym_month } = res.data;
          this.pmyDate = `${pym_year}-${pym_month}`;
        }
      });
    },
    fetchEmployees() {
      this.apiGet(this.ROUTES.employee, "Get Employees Error").then((res) => {
        const { data } = res;
        this.employees = [{ value: null, text: "Please select an employee" }];
        data.forEach((employee) => {
          this.employees.push({
            value: employee.emp_id,
            text: `${employee.emp_first_name} ${
              employee.emp_other_name ?? ""
            } ${employee.emp_last_name} - ${employee.emp_unique_id}`,
          });
        });
      });
    },
    employeeLabel({ text }) {
      return `${text}`;
    },
    resetForm() {
      this.employee = null;
      this.$v.$reset();
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    submitNew() {
      this.submitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.apiFormHandler("Invalid Exemption");
      } else {
        this.submitting = true;
        console.log(this.employee);
        const data = {
          ps_empid: this.employee.value,
        };
        const url = `${this.ROUTES.salary}/pause-salary`;
        this.apiPost(url, data, "Add Exemption Error")
          .then((res) => {
            this.apiResponseHandler(`${res.data}`, "New Exemption Added");
            this.refreshTable();
            this.$v.$reset();
            this.submitting = false;
            this.addExemption = false;
          })
          .finally(() => {
            this.submitting = false;
          });
      }
    },
    confirmRemove(rowItem) {
      this.removeExemption = rowItem;
      this.confirmRemoveExemption = true;
    },
    removeExemptionRoutine() {
      this.removing = true;
      const url = `${this.ROUTES.salary}/unpause-salary`;
      const data = {
        ps_id: parseInt(this.removeExemption.ps_id),
      };
      this.apiPost(url, data, "Remove Exemption Error")
        .then((res) => {
          this.apiResponseHandler(`${res.data}`, "Exemption Removed");
          this.refreshTable();
          this.confirmRemoveExemption = false;
        })
        .finally(() => {
          this.removing = false;
        });
    },
  },
  data() {
    return {
      title: "Exemption",
      items: [
        {
          text: "IHUMANE",
        },
        {
          text: "Dashboard",
          href: "/",
        },
        {
          text: "Exemption",
          active: true,
        },
      ],
      exemptions: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "sn",
      sortDesc: false,
      fields: [
        { key: "sn", label: "S/n", sortable: true, thStyle: { width: "5%" } },
        { key: "employee", label: "Employee", sortable: true },
        { key: "location", label: "Location", sortable: true },
        { key: "month", label: "Month", sortable: true },
        { key: "year", label: "Year", sortable: true },
        {
          key: "action",
          label: "Actions",
          sortable: true,
          thStyle: { width: "5%" },
        },
      ],
      submitted: false,
      submitting: false,
      removing: false,
      addExemption: false,
      confirmRemoveExemption: false,
      employees: [{ value: null, text: "Please select an employee" }],
      employee: null,
      empId: null,
      pmyMonth: null,
      pmyYear: null,
      pmyDate: null,
      removeExemption: null,
    };
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="d-flex justify-content-end mb-3">
      <b-button class="btn btn-success" @click="addExemption = true">
        <i class="mdi mdi-plus mr-2"></i>
        Add Exemption
      </b-button>
    </div>
    <scale-loader v-if="apiBusy" />
    <div v-else class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row mt-4">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Show&nbsp;
                    <b-form-select
                      v-model="perPage"
                      size="sm"
                      :options="pageOptions"
                    ></b-form-select
                    >&nbsp;entries
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div
                  id="tickets-table_filter"
                  class="dataTables_filter text-md-right"
                >
                  <label class="d-inline-flex align-items-center">
                    Search:
                    <b-form-input
                      v-model="filter"
                      type="search"
                      placeholder="Search..."
                      class="form-control form-control-sm ml-2"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0">
              <b-table
                ref="exemptions-table"
                bordered
                selectable
                hover
                :items="exemptions"
                :fields="fields"
                responsive="sm"
                :per-page="perPage"
                :current-page="currentPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
                show-empty
                select-mode="single"
              >
                <template #cell(month)="row">
                  <span>{{ (parseFloat(row.value) - 1) | getMonth }}</span>
                </template>
                <template #cell(action)="row">
                  <button
                    type="button"
                    class="btn btn-sm btn-danger"
                    @click="confirmRemove(row.item)"
                  >
                    Remove
                  </button>
                </template>
              </b-table>
            </div>
            <div class="row">
              <div class="col">
                <div
                  class="dataTables_paginate paging_simple_numbers float-right"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="totalRows"
                      :per-page="perPage"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-modal
      v-model="addExemption"
      title="Add Exemption"
      hide-footer
      centered
      title-class="font-18"
      @hidden="resetForm"
    >
      <form @submit.prevent="submitNew">
        <div class="form-group">
          <label for="name">
            Employee <span class="text-danger">*</span>
          </label>
          <multiselect
            v-model="employee"
            :options="employees"
            :custom-label="employeeLabel"
            :class="{
              'is-invalid': submitted && $v.employee.$error,
            }"
          ></multiselect>
        </div>
        <div class="form-group">
          <label for="pmy"> Current Payroll Period </label>
          <input
            id="pmy"
            v-model="pmyDate"
            type="month"
            class="form-control"
            disabled
          />
        </div>
        <b-button
          v-if="!submitting"
          class="btn btn-success btn-block mt-4"
          type="submit"
        >
          Submit
        </b-button>
        <b-button
          v-else
          disabled
          class="btn btn-success btn-block mt-4"
          type="submit"
        >
          Submitting...
        </b-button>
      </form>
    </b-modal>

    <b-modal
      v-model="confirmRemoveExemption"
      centered
      title-class="text-black font-18"
      body-class="p-3"
      hide-footer
      hide-header
    >
      <div class="text-center">
        <i
          class="mdi mdi-alert-octagon-outline text-warning"
          style="font-size: 4em"
        />
        <h5 class="mt-n3 text-warning">Confirm Exemption Removal</h5>
      </div>
      <div class="alert alert-warning mt-3" v-if="removeExemption">
        Are you sure you want to remove the following salary routine exemption:
        <p class="m-0 mt-2">Employee: {{ removeExemption.employee }}</p>
        <p class="m-0">Location: {{ removeExemption.location }}</p>
        <p class="m-0">
          Payroll Period:
          {{ `${removeExemption.year}-${removeExemption.month}` }}
        </p>
      </div>
      <div class="text-center">
        <button
          type="button"
          class="btn btn-sm btn-success mr-2"
          @click="confirmRemoveExemption = false"
        >
          Cancel
        </button>
        <button
          v-if="!removing"
          type="button"
          class="btn btn-sm btn-danger"
          @click="removeExemptionRoutine"
        >
          Remove
        </button>
        <button v-else type="button" disabled class="btn btn-sm btn-danger">
          Removing...
        </button>
      </div>
    </b-modal>
  </Layout>
</template>
